"use client";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useCallback, useEffect, useRef, useState } from "react";
import { BsList, BsX } from "react-icons/bs";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import { LinkInfo } from "./Header";
import { Locale } from "../../common/i18n/config";
import { setUserLocale } from "../../common/i18n/locale";
import LanguageSelect from "../Misc/LanguageSelect";
import { useLocale } from "next-intl";

interface SideNavBarProps {
  links: LinkInfo[];
}

export default function SideNavBar({ links }: SideNavBarProps) {
  const [isOpen, setIsOpen] = useState(false);
  const dialog = useRef<HTMLDialogElement>(null);
  const lang = useLocale();
  const pathname = usePathname();

  const toggleSidebar = useCallback(() => {
    if (dialog.current?.open) {
      dialog.current.showModal()
    } else {
      dialog.current?.close();
    }
    setIsOpen(!isOpen)
  }, [dialog, setIsOpen, isOpen])

  const closeSidebar = useCallback(() => {
    setIsOpen(false);
  }, []);

  // Close the sidebar when the route changes
  useEffect(() => {
    if (isOpen) {
      closeSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <>
      <button className="icon-button text-primary-main" onClick={toggleSidebar}>
        <BsList size={35} />
      </button>
      {/* Overlay for dimming background */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black/50 z-40"
          onClick={closeSidebar}
        ></div>
      )}
      <div className="relative z-50">
        {/* Sidebar */}
        <div
          className={`fixed top-0 right-0 h-full w-64 bg-dark text-white transform ${isOpen ? "translate-x-0" : "translate-x-full"
            } transition-transform duration-300 ease-in-out`}
        >
          <div className="p-4 flex justify-between items-center">
            <LanguageSelect
              className="ml-0 pl-0"
              initialLang={lang}
              onChange={(lang) => {
                setUserLocale(lang as Locale);
              }}
            />
            {/* Close button */}
            <button
              onClick={toggleSidebar}
              className="icon-button text-primary-main"
            >
              <BsX size={32} />
            </button>
          </div>
          {/* Sidebar content */}
          <SideNavLink links={links}></SideNavLink>
        </div>
      </div>
    </>
  );
}

interface SideNavLinkProps {
  links: LinkInfo[];
}

function SideNavLink({ links }: SideNavLinkProps) {
  return (
    <nav className="flex flex-col gap-4">
      {links.map((link, i) => (
        <div
          key={i}
          className={`ml-5 text-start flex ps-0 ${link.classLink} content-center text-lg font-semibold`}
        >
          {link.subLinks ? (
            <div key={i}>
              <Accordion links={link.subLinks} text={link.text}></Accordion>
            </div>
          ) : link.element ? (
            link.element
          ) : (
            <label>
              <Link
                href={link.link || "/"}
                className="icon-button shadow-none text-primary-main min-h-9"
              >
                {link.text}
                {link.logo}
              </Link>
            </label>
          )}
        </div>
      ))}
    </nav>
  );
}

interface AccordionProps {
  links: LinkInfo[];
  text: string;
}

function Accordion({ text, links }: AccordionProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex text-left p-0 m-0 bg-transparent border-none rounded-none min-h-9 items-center"
      >
        <span className="text-primary-main text-lg font-semibold">{text}</span>
        <span className="flex items-center justify-center ml-1" aria-hidden="true">
          {isOpen ? <MdArrowDropUp /> : <MdArrowDropDown />}
        </span>
      </button>
      {isOpen && (
        <div className="ml-3 space-y-2">
          {links.map((link, i) => (
            <div key={i}>
              <label>
                <Link
                  href={link.link || "/"}
                  className="icon-button shadow-none text-primary-main justify-start mt-2 font-medium"
                >
                  {link.text}
                  {link.logo}
                </Link>
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
