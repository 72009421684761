"use client"
import classNames from 'classnames'
import { useLocale, useTranslations } from 'next-intl'
import Image from 'next/image'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { Locale } from '../../common/i18n/config'
import { setUserLocale } from '../../common/i18n/locale'
import Dropdown from '../Misc/Dropdown'
import LanguageSelect from '../Misc/LanguageSelect'
import SideNavBar from './SideNav'
import { MdOutlineMailOutline } from "react-icons/md";
// interface HeaderProps {
//   links?: LinkInfo[]
// }

export interface LinkInfo {
  text: string
  img?: string
  link?: string
  logo?: JSX.Element
  subLinks?: LinkInfo[]
  classLink?: string
  element?: JSX.Element
}

export default function Header() {
  const t = useTranslations();
  const lang = useLocale();
  const links: LinkInfo[] = [
    {
      text: t("links.home"),
      link: "/home",
    },
    {
      text: t("links.about-us"),
      link: "/about-us",
    },
    {
      text: t("links.game-rooms"),
      link: "/game-rooms"
    },
    {
      text: t("links.services"),
      link: "/services",
    },
    {
      text: t("links.contacts"),
      link: "/game-rooms#headquarters-title",
    },
    {
      text: t("links.work-with-us"),
      link: "/work-with-us",
    },
    {
      text: t("links.blond-and-events"),
      link: "/blog",
    },
  ]


  return (
    <header className="shadow-lg z-10">
      <div className="flex lg:hidden items-center justify-between py-[10px] px-5">
        <Link href={"/"}>
          <Image
            src={"/logo.png"}
            alt='logo'
            width={48}
            height={48}
          />
        </Link>
        <div className={`inline lg:hidden`}>
          <SideNavBar links={links} />
        </div>
      </div>
      <div className={"hidden lg:flex p-2 justify-around items-center"}>
        <Link href={"/"}>
          <Image
            src={"/logo.png"}
            alt='logo'
            width={52}
            height={52}
          />
        </Link>
        <SubHeader links={links} />
        <div className='inline-flex items-center'>
          <Link
            title={t('contact-form.title')}
            href={"/home#contact-form"}
          >
            <MdOutlineMailOutline size={24} />
          </Link>
          <LanguageSelect
            initialLang={lang}
            onChange={(lang) => {
              setUserLocale(lang as Locale);
            }}
          />
        </div>
      </div>
    </header>
  )
}

interface SubHeaderProps {
  links: LinkInfo[]
}

function SubHeader({ links }: SubHeaderProps) {
  const asPath = usePathname();
  const isActive = (link: LinkInfo) => (link.subLinks ?? []).map(({ link }) => link).concat(link.link).includes(asPath);

  return (
    <nav className={`container content-center flex justify-center gap-8`}>
      {links.map((link, i) => (
        <div key={i} className="flex flex-col">
          {link.subLinks ? (
            <div className={`inline p-3 my-1 ${i === 0 ? "pl-0" : ""}`}>
              <Dropdown id={`subToolbarLink${i}`} text={link.text} links={link.subLinks}
                buttonClass="p-0 w-full block text-base border-none"
                linkClass='min-w-80 max-w-full top-8'
              />
            </div>
          ) : (
            <Link
              href={`${link.link}`}
              className={classNames(
                'font-semibold border-y-2 box-border uppercase text-xl p-3',
                {
                  'border-solid border-gold text-gold': isActive(link),
                  'border-transparent': !isActive(link),
                }
              )}
            >
              {link.text}
            </Link>
          )}
        </div>
      ))}
    </nav>
  )
}
