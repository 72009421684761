import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"700\",\"900\"],\"variable\":\"--font-roboto\"}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Work_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"600\",\"700\",\"900\"],\"variable\":\"--font-workSans\"}],\"variableName\":\"workSans\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"700\",\"900\"],\"variable\":\"--font-montserrat\"}],\"variableName\":\"montserrat\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Layout/Header.tsx");
;
import(/* webpackMode: "eager" */ "/app/styles/globals.scss");
