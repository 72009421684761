"use client"

import { useTranslations } from "next-intl";
import { ChangeEvent, useState } from "react";
import { Locale, locales } from "../../common/i18n/config";

const flagsMap = new Map<Locale, string>([
  ["it", "🇮🇹"],
  ["en", "🇬🇧"],
  ["zh", "🇨🇳"],
  ["es", "🇪🇸"]
]);

interface LanguageSelectProps {
  onChange?: (lang: string) => void;
  initialLang?: string;
  className?: string;
}

function LanguageSelect({ onChange, initialLang, className = "" }: LanguageSelectProps) {
  const t = useTranslations();
  const [lang, setLang] = useState<string>(initialLang ?? "it");

  const handleLangChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const lang = e.target.value;
    setLang(lang);
    onChange?.(lang);
  };

  return (<select className={`select py-0 bg-dark ${className}`} name="language" id="language-select" value={lang}
    onChange={handleLangChange}
  >
    {locales.map((l) => (<option key={l} value={l}>{flagsMap.get(l)} {t(`lang.${l}`)}</option>))}
  </select>);
}

export default LanguageSelect;