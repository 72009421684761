"use client"
import { useEffect, useRef, useState } from "react";
import Link from "next/link";
import { LinkInfo } from "../Layout/Header";
import Image from "next/image";
import classNames from "classnames";
import { IoMdArrowDropdown } from "react-icons/io";

interface DropdownProps {
  text: string;
  links?: LinkInfo[],
  id: string,
  buttonClass?: string,
  linkClass?: string
}

export default function Dropdown({ text, links, id, buttonClass, linkClass }: DropdownProps) {
  const [openDropdownId, setOpenDropdownIndex] = useState<string | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleDropdownToggle = (id: string) => {
    setOpenDropdownIndex(openDropdownId === id ? null : id);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setOpenDropdownIndex(null);
    }
  };

  useEffect(() => {
    // Aggiungi il listener quando il dropdown è aperto
    if (openDropdownId) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Rimuovi il listener quando il componente viene smontato
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openDropdownId]); // Riesegue quando openDropdownId cambia


  return <div className='relative block' ref={dropdownRef}>
    <button
      id={id}
      onClick={() => handleDropdownToggle(id)}
      className={`cursor-pointer dropdown flex items-center justify-center gap-2 ${openDropdownId === id ? "text-gold" : ""} ${buttonClass}`}
    >
      {text}
      <IoMdArrowDropdown className={`${openDropdownId === id ? "rotate-180" : ""}`} />
    </button >

    {/* Condizione per mostrare/nascondere la dropdown */}
    <nav
      id={id}
      className={classNames("z-10 absolute w-80 bg-secondary/90 left-1/2 -translate-x-1/2", linkClass, {
        "block": openDropdownId === id,
        "hidden": openDropdownId !== id
      })}
    >
      {links?.map((link, i) => (
        <Link
          key={i}
          onClick={() => handleDropdownToggle(id)}
          href={`${link.link}`}
          className={classNames(`flex items-center font-semibold text-lg py-2 w-full px-3 hover:text-gold duration-200`, {
            "border-t border-solid border-light": i !== 0
          })}
        >
          {link.img && <Image
            src={link.img}
            alt={link.text}
            width={32}
            height={32}
            className='rounded-sm inline mx-auto'
          />}
          <span title={link.text} className="mx-8 truncate inline-block w-full">
            {link.text}
          </span>
        </Link>
      ))}

    </nav >
  </div >
}